var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"卡片材质"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入卡片材质' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入卡片材质' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"卡片尺寸","required":""}},[_c('a-row',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addCardShape}},[_vm._v(" 新增 ")])],1)],1),_c('a-form-item',{staticClass:"card-shape",attrs:{"wrapper-col":{ span: 15, offset: 6}}},_vm._l((_vm.form.getFieldValue('keys')),function(k){return _c('a-row',{key:k,attrs:{"label":"","required":false}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',[_c('a-space',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([("card_shape_names[" + k + "]"),{
                    normalize: _vm.$lodash.trim,
                    initialValue: _vm.cardShapeNames[k],
                    rules: [
                      { required: true, message: '请输入卡片尺寸' },
                      { max: 50, message: '最多50个字符' },
                      { validator: _vm.checkCardShapeName }
                    ],
                  }]),expression:"[`card_shape_names[${k}]`,{\n                    normalize: $lodash.trim,\n                    initialValue: cardShapeNames[k],\n                    rules: [\n                      { required: true, message: '请输入卡片尺寸' },\n                      { max: 50, message: '最多50个字符' },\n                      { validator: checkCardShapeName }\n                    ],\n                  }]"}],staticStyle:{"width":"295px"},on:{"change":_vm.validateCardShapeNames}}),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.getFieldValue('keys').length > 1),expression:"form.getFieldValue('keys').length > 1"}],staticClass:"dynamic-delete-button",attrs:{"type":"delete","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.removeCardShape(k); }}})],1)],1)],1)],1)}),1),_c('a-form-item',{attrs:{"label":"说明"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
            rules: [
              { max: 200, message: '最多200个字符' }
            ]
          }]),expression:"['description', {\n            rules: [\n              { max: 200, message: '最多200个字符' }\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }